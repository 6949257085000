@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
  --primaryColor: #8bd4db;
  --secondaryColor: #1d99d0;
  --ternaryColor: #263988;
  --buttonColor: #db6700;
  --textColor: #000;
  --whiteColor: #fff;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  font-size: inherit;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
