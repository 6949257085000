@import "../../styleFunctions/mixins";

.accordianBox {
  @include flexContainer($gap: 2rem);
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  padding: 1.6rem 3.2rem;
  background-color: transparent;
  border-radius: 10px;
  outline: none;
  border: 0.2rem solid var(--whiteColor);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  .accordianHeader {
    @include flexContainer;
    width: 100%;
    background: none;
    border: none;
    > span {
      flex: 1 0 40%;
      font-weight: 500;
      &:nth-of-type(1) {
        text-align: left;
        font-size: 1.6rem;
        color: var(--whiteColor) !important;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 2.5px;
        text-transform: uppercase;
      }
      &:nth-of-type(2) {
        text-align: right;
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }
  .accordianBody {
    width: 100%;
    p {
      margin: 0;
      color: var(--whiteColor);
      font-size: 1.6rem;
    }
    .nestedList {
      @include flexContainer;
      flex-direction: column;
      li {
        width: 100%;
        a {
          width: 100%;
          border: none;
          text-align: left;
          color: var(--whiteColor) !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          background-color: transparent !important;
          svg {
            transform: rotate(270deg) !important;
          }
        }
      }
    }
    .staffList {
      @include flexContainer;
      flex-direction: column;
      li {
        width: 100%;
        @include flexContainer;
        flex-wrap: wrap;
        > span {
          flex-grow: 1;
          flex-basis: 50px;
          color: var(--whiteColor);
          font-size: 1.6rem;
          &:nth-of-type(1) {
            text-align: left;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  &:hover {
    background-color: var(--buttonColor);
  }
}
.activeAccordian {
  background-color: transparent !important;
  .accordianHeader {
    > span {
      &:nth-of-type(2) {
        svg {
          transform: rotate(0deg) !important;
        }
      }
    }
  }
}
