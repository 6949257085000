@import "../../../styleFunctions/mixins";

.sectionContainer {
    > .logoContainer {
        transform: rotate(270deg) !important;
    }
    .sectionContent {
        padding-inline: 20px;
        width: 100%;
        .logoContainer {
            text-align: center;
            margin-bottom: 20px;
            img {
                max-width: 250px;
            }
        }
        .sectionInner {
            text-align: center;
            img {
                max-width: 400px;
            }
            .btnContainer {
                @include flexContainer;
                max-width: 80%;
                margin: 20px auto 0;
                > a {
                    width: 100%;
                }
            }
        }
    }
}

.activeBox {
    > .logoContainer {
        display: none !important;
    }
}