@import "../../styleFunctions/mixins";

.homePage {
  @include flexContainer($alignItems: "stretch", $gap: 0);
  overflow: hidden;
  > div {
    flex-grow: 1;
    flex-basis: 200px;
    @include flexContainer;
    flex-direction: column;
    &:nth-of-type(1) {
      background-color: var(--whiteColor);
      color: #fff;
      flex-basis: 600px;
    }
    &:nth-of-type(2) {
      background-color: var(--primaryColor);
      color: #fff;
    }
    &:nth-of-type(3) {
      background-color: var(--secondaryColor);
      color: #fff;
    }
    &:nth-of-type(4) {
      background-color: var(--ternaryColor);
      color: #fff;
    }
    &:nth-of-type(5) {
      background-color: var(--buttonColor);
      color: #000;
    }
  }
}