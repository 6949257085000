@import "../../../styleFunctions/mixins";

.sectionContainer {
  position: relative;
  > .sectionTitle {
    transform: rotate(270deg) !important;
    width: 200%;
    position: absolute;
    h2 {
      text-align: center;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .sectionContent {
    padding: 40px 20px;
    width: 100%;
    background-color: #db6700ba;
    max-width: 95%;
    .sectionTitle {
      text-align: center;
      margin-bottom: 20px;
      h2 {
        text-align: center;
      }
    }
    .sectionInner {
      text-align: center;
      margin-top: 3rem;
      .btnContainer {
        @include flexContainer;
        // max-width: 80%;
        margin: 20px auto 0;
        flex-wrap: wrap;
        max-height: 500px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 7px; /* Set the width of the scrollbar */
          background-color: transparent !important; /* Set the background color of the scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: #1d99d0bd;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px var(--buttonColor);
        }
        > a {
          width: 100%;
          border-color: var(--whiteColor);
          color: var(--whiteColor) !important;
          flex: 1 0 263px;
        }
      }
    }
  }
}

.activeBox {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  > .sectionTitle {
    display: none !important;
  }
}
